import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kenfranco/Documents/dev/medgrocer/pp-jn-website/src/components/Layout/Layout.js";
import { Link } from "gatsby";
import Container from "layout/Container";
import SEO from "layout/SEO";
import Collapsible from "elements/Collapsible";
import ContactUs from "../components/StaticPages/HelpCenter/ContactUs";
import HelpCenterTable from "../components/StaticPages/HelpCenter/HelpCenterTable";
import courierTat from "../components/StaticPages/utils/helpCenter/courierTat.json";
import courierServiceCharges from "../components/StaticPages/utils/helpCenter/courierServiceCharges.json";
import paymentOptions from "../components/StaticPages/utils/helpCenter/paymentOptions.json";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Container isCentered mdxType="Container">
      <SEO title="Frequently Asked Questions" mdxType="SEO" />
      <h2>{`Frequently Asked Questions`}</h2>
      <hr />
      <h4>{`Doctor Enrollment`}</h4>
      <Collapsible title="Am I eligible to join the program?" mdxType="Collapsible">
        <p>{`All doctors with active PRC license in the Philippines are eligible to join this program.`}</p>
      </Collapsible>
      <Collapsible title="How do I become a Partner Doctor?" mdxType="Collapsible">
        <p>{`To become a Partner Doctor, fill up the online Doctor Enrollment Form at `}<a className='has-text-primary' href='https://careplus.medgrocer.com/enroll-doctor' target='blank' rel='noreferrer noopener'>{`careplus.medgrocer.com`}</a>{`. You will receive an email confirming your eligibility. Once you receive the email, you may start instructing your patients to enroll in the program.`}</p>
      </Collapsible>
      <Collapsible title="How do I instruct patients to enroll in the program?" mdxType="Collapsible">
        <p>{`After receiving your enrollment confirmation, you will receive a soft copy of the patient educational materials through email. You can use these materials to instruct your patients to enroll in the program.`}</p>
      </Collapsible>
      <h4>{`Patient Enrollment`}</h4>
      <Collapsible title="Am I eligible to join the program?" mdxType="Collapsible">
        <p>{`You are eligible to join the program if:`}</p>
        <ul>
          <li parentName="ul">{`You reside in the Philippines`}</li>
          <li parentName="ul">{`Your attending doctor is enrolled in the program and is a licensed physician in the Philippines`}</li>
          <li parentName="ul">{`You are prescribed with any of the following medicines:`}<ul parentName="li">
              <li parentName="ul">{`Abiraterone acetate 500mg Tablet (Zytiga)`}</li>
              <li parentName="ul">{`Apalutamide 60mg Tablet (Erleada)`}</li>
              <li parentName="ul">{`Amivantanab 350mg (Rybrevant)`}</li>
              <li parentName="ul">{`Bendamustine hydrochloride 100mg Vial (Ribomustin)`}</li>
              <li parentName="ul">{`Bendamustine hydrochloride 25mg Vial (Ribomustin)`}</li>
              <li parentName="ul">{`Bortezomib 3.5mg Solution for Injection (Velcade)`}</li>
              <li parentName="ul">{`Daratumumab 100mg/5mL Vial (Darzalex)`}</li>
              <li parentName="ul">{`Daratumumab 400mg/20mL Vial (Darzalex)`}</li>
              <li parentName="ul">{`Daratumumab 1800mg Vial (Darzalex SC)`}</li>
              <li parentName="ul">{`Golimumab 50mg/0.5mL Auto-injection (Simponi)`}</li>
              <li parentName="ul">{`Golimumab 50mg/4mL Vial (Simponi Aria)`}</li>
              <li parentName="ul">{`Guselkumab 100mg Pre-filled pen (Tremfya)`}</li>
              <li parentName="ul">{`Macitentan 10mg Tablet (Opsumit)`}</li>
              <li parentName="ul">{`Paliperidone palmitate 100mg Injection (Invega Sustenna)`}</li>
              <li parentName="ul">{`Paliperidone palmitate 150mg Injection (Invega Sustenna)`}</li>
              <li parentName="ul">{`Paliperidone palmitate 75mg Injection (Invega Sustenna)`}</li>
              <li parentName="ul">{`Selexipag 200mcg Tablet (Uptravi)`}</li>
              <li parentName="ul">{`Selexipag 400mcg Tablet (Uptravi)`}</li>
              <li parentName="ul">{`Selexipag 800mcg Tablet (Uptravi)`}</li>
              <li parentName="ul">{`Selexipag 1600mcg Tablet (Uptravi)`}</li>
              <li parentName="ul">{`Ustekinumab 90mg/1mL Pre-filled syringe (Stelara)`}</li>
              <li parentName="ul">{`Ustekinumab 130mg 5mg/mL Vial (Stelara)`}</li>
            </ul></li>
        </ul>
      </Collapsible>
      <Collapsible title="How do I enroll in the CarePlus program?" mdxType="Collapsible">
        <p>{`To become a CarePlus patient, create an account by filling out the online Patient Enrollment Form at `}<a parentName="p" {...{
            "href": "https://careplus.medgrocer.com/enroll-patient"
          }}>{`https://careplus.medgrocer.com/enroll-patient`}</a>{`. We will send you an SMS verifying your eligibility and activate your account within one working day.`}</p>
      </Collapsible>
      <Collapsible title="I already enrolled but I still can’t sign in." mdxType="Collapsible">
        <p>{`Your account may still be under review. Please give us one working day to activate your account. You will receive an SMS once your account is activated.`}</p>
      </Collapsible>
      <Collapsible title="What if I forgot my password?" mdxType="Collapsible">
        <p>{`To reset your password, please follow the instructions below:`}</p>
        <ol>
          <li parentName="ol">{`Visit `}<a href="/forgot-password">{`careplus.medgrocer.com/forgot-password`}</a>{`.`}</li>
          <li parentName="ol">{`Enter your email and click “Reset Password”.`}</li>
          <li parentName="ol">{`Check your email for the password reset email. Click the link provided and create a new password.`}</li>
        </ol>
      </Collapsible>
      <Collapsible title="What if I forgot my email?" mdxType="Collapsible">
        <p>{`To recover your email, please follow the instructions below:`}</p>
        <ol>
          <li parentName="ol">{`Visit `}<a href="/recover-email">{`careplus.medgrocer.com/recover-email`}</a>{`.`}</li>
          <li parentName="ol">{`Enter enrolled patient name, mobile number, and birthday.`}</li>
        </ol>
        <p>{`Our coordinator will review the information you have provided and send your
registered email through SMS after validation.`}</p>
      </Collapsible>
      <Collapsible title="How would I know if my enrollment went through?" mdxType="Collapsible">
        <p>{`You will receive an email acknowledging your enrollment and an SMS verifying your eligibility. If you do not receive an SMS from MedGrocer, email us at careplus@medgrocer.com.`}</p>
      </Collapsible>
      <Collapsible title="Can I enroll for someone else?" mdxType="Collapsible">
        <p>{`Yes, you may assist another person in enrolling in the program.`}</p>
      </Collapsible>
      <h4>{`Prescription`}</h4>
      <Collapsible title="Do I need a prescription?" mdxType="Collapsible">
        <p>{`Yes. We require a valid prescription which contains the following details: prescription date, patient’s name, prescriber’s name, prescriber’s license number, generic or brand name, dosage strength, and total quantity prescribed.`}</p>
      </Collapsible>
      <Collapsible title="How do I send my prescription?" mdxType="Collapsible">
        <p>{`You can upload a picture or a scanned copy of your prescription to the website upon placing your order.`}</p>
      </Collapsible>
      <Collapsible title="Do I have to surrender my prescription?" mdxType="Collapsible">
        <p>{`You don’t have to surrender your prescription. However, you must present the original copy of the prescription to our courier upon the delivery of your order.`}</p>
      </Collapsible>
      <h4>{`Orders`}</h4>
      <Collapsible title="How do I order?" mdxType="Collapsible">
        <ol>
          <li parentName="ol">{`Make sure that you are enrolled in the program. This is a one-time enrollment`}</li>
          <li parentName="ol">{`Once you receive a message confirming your eligibility, go to `}<a className='has-text-primary' href='/' target='blank' rel='noreferrer noopener'>{`careplus.medgrocer.com`}</a>{` to order`}</li>
          <li parentName="ol">{`Sign in with your credentials`}</li>
          <li parentName="ol">{`Input the medicine and quantity you want to purchase`}</li>
          <li parentName="ol">{`Upload a clear photo of your prescription`}</li>
          <li parentName="ol">{`Input your delivery details`}</li>
          <li parentName="ol">{`Select your preferred dispensing channel and payment method`}</li>
          <li parentName="ol">{`Review your details and submit your order`}</li>
          <li parentName="ol">{`You will receive a message after your order has been processed`}</li>
        </ol>
      </Collapsible>
      <Collapsible title="How do I pay for my order?" mdxType="Collapsible">
        <p>{`Payment methods vary per dispensing channel.`}</p>
        <HelpCenterTable data={paymentOptions} mdxType="HelpCenterTable" />
      </Collapsible>
      <Collapsible title="How much is the service charge for credit card payments?" mdxType="Collapsible">
        <p>{`Please coordinate with your preferred dispensing channel for any applicable credit card charges.`}</p>
      </Collapsible>
      <Collapsible title="Do you honor Senior Citizen/Person with Disability discounts?" mdxType="Collapsible">
        <p>{`Promotions such as medicine assistance may not be used in conjunction with government-mandated discounts such as Senior Citizen/Person with Disability (SC/PWD) discount unless otherwise specified by the promotion mechanics.`}</p>
      </Collapsible>
      <Collapsible title="How would I know if my order went through?" mdxType="Collapsible">
        <p>{`You will receive a message with your reference number acknowledging your order. If you do not receive a message from CarePlus within 5 minutes, email us at careplus@medgrocer.com.`}</p>
      </Collapsible>
      <Collapsible title="How long does it take to process my order?" mdxType="Collapsible">
        <p>{`We process orders from 8:30AM to 4:30PM on weekdays. Orders made during that time are processed within the day. Orders made during the weekends or in the evenings are processed on the next working day.`}</p>
      </Collapsible>
      <Collapsible title="Can I modify my order?" mdxType="Collapsible">
        <p>{`To prevent miscommunications and minimize inaccuracies, orders are considered final upon checkout. For any revisions, please order through the website again.`}</p>
      </Collapsible>
      <Collapsible title="How do I cancel my order?" mdxType="Collapsible">
        <p>{`Send us a message with your order reference number to confirm the status of your order.`}</p>
      </Collapsible>
      <Collapsible title="Can I order for someone else?" mdxType="Collapsible">
        <p>{`Only CarePlus patients and their authorized representatives may avail of the medicine assistance provided by the program.`}</p>
      </Collapsible>
      <Collapsible title="How can I contact CarePlus?" mdxType="Collapsible">
        <p>{`If you have questions or are experiencing problems not found in our Frequently Asked Questions, you may email us at careplus@medgrocer.com or send us an SMS at +63917 866 9487.`}</p>
      </Collapsible>
      <h4>{`Delivery`}</h4>
      <Collapsible title="Which dispensing channels can I choose from to deliver my medicines?" mdxType="Collapsible">
        <p>{`Depending on your location and preferred payment method, you may choose to have your medicines delivered by your preferred dispensing channel upon checkout.`}</p>
      </Collapsible>
      <Collapsible title="What are the areas covered for delivery?" mdxType="Collapsible">
        <p>{`The Program supports nationwide delivery.`}</p>
      </Collapsible>
      <Collapsible title="Is there a delivery fee?" mdxType="Collapsible">
        <p>{`A delivery fee may apply depending on your chosen dispensing channel.`}</p>
      </Collapsible>
      <Collapsible title="Can I pick up the medicines in the MedGrocer office?" mdxType="Collapsible">
        <p>{`We streamlined our operations to give you a hassle-free experience. Please use our delivery service for your convenience.`}</p>
      </Collapsible>
      <Collapsible title="When will I receive my order?" mdxType="Collapsible">
        <p>{`The turnaround time for the delivery of your medicine depends on your chosen dispensing channel:`}</p>
        <HelpCenterTable data={courierTat} mdxType="HelpCenterTable" />
        <p>{`Deliveries typically happen in 1 to 5 days, depending on your city. However, medicine availability and quarantine restrictions may make this longer.`}</p>
      </Collapsible>
      <Collapsible title="How can I schedule the delivery of my order or follow up its status?" mdxType="Collapsible">
        <p>{`To know the status of your order, send us an email through careplus@medgrocer.com.`}</p>
      </Collapsible>
      <Collapsible title="What if I’m not at home when the medicines arrive?" mdxType="Collapsible">
        <p>{`Your authorized representative should present a copy of your ID and prescription to receive your medicines.`}</p>
      </Collapsible>
      <h4>{`Special Requests`}</h4>
      <Collapsible title="Can I still avail of the benefits under the CarePlus Program if I don’t want to disclose my personal information?" mdxType="Collapsible">
        <p>{`Please email careplus@medgrocer.com for assistance.`}</p>
      </Collapsible>
      <h4>{`Returns`}</h4>
      <Collapsible title="What is your return policy?" mdxType="Collapsible">
        <p>{`For MedGrocer, all medicines delivered are subject to quality checks by our purchasing, storage, assembly, and delivery teams.`}</p>
        <p>{`Please always inspect your medicines upon delivery. If your medicines are damaged upon delivery, you may refuse to accept the medicines. Returns are not accepted if products are:`}</p>
        <ul>
          <li parentName="ul">{`Stored between 2 to 8°C`}</li>
          <li parentName="ul">{`Damaged due to termites, fire, smoke, water, heat, and earthquake`}</li>
          <li parentName="ul">{`Have a broken seal`}</li>
          <li parentName="ul">{`Obtained as free goods or samples`}</li>
          <li parentName="ul">{`In original packs but in various lots or batch numbers`}</li>
          <li parentName="ul">{`Loose items`}</li>
        </ul>
        <p>{`On a case-to-case basis, we can process requests to return the medicines within seven days upon delivery. Please make sure that you keep your delivery receipt and the medicines you wish to return.`}</p>
        <p>{`For other distributors, please email careplus@medgrocer.com for assistance.`}</p>
      </Collapsible>
      <Collapsible title="What medicines will you accept for returns?" mdxType="Collapsible">
        <p>{`We only replace damaged or wrongly dispensed medicines.`}</p>
      </Collapsible>
      <Collapsible title="Can I return an item after seven days?" mdxType="Collapsible">
        <p>{`For MedGrocer, any problems encountered seven days after the delivery may not be accommodated under our return policy. We highly encourage you to inspect your medicines upon delivery so that our courier could immediately facilitate your return, exchange, or refund request.`}</p>
        <p>{`For other distributors, please email careplus@medgrocer.com for assistance.`}</p>
      </Collapsible>
      <Collapsible title="How long is the processing time for returns?" mdxType="Collapsible">
        <p>{`For MedGrocer, returns are processed within 2-3 working days for all Metro Manila deliveries after approval.`}</p>
        <p>{`For other distributors, please email careplus@medgrocer.com for assistance.`}</p>
      </Collapsible>
      <h4>{`Privacy`}</h4>
      <Collapsible title="What information do you share?" mdxType="Collapsible">
        <p>{`Only our pharmacists processing your enrollment have access to your medical information to ensure your medicines are properly dispensed. Your treatment adherence information and reported adverse events may be shared with your doctor upon request. We may use anonymized data for analytics to improve our services or collaborate with partners.`}</p>
      </Collapsible>
      <Collapsible title="How do I deactivate my account or contact the data privacy officer?" mdxType="Collapsible">
        <p>{`MedGrocer’s Data Privacy Officer provides support with any data privacy related questions, comments, concerns, or complaints. You may contact MedGrocer’s Data Privacy Officer through the following information:`}</p>
        <div>
  Address: MedGrocer 24F Centuria Medical Makati, Kalayaan cor. Salamanca St.,
  Makati City
        </div>
        <div>Email address: dpo@medgrocer.com</div>
      </Collapsible>
      <h4>{`Regulatory`}</h4>
      <Collapsible title="What is MedGrocer?" mdxType="Collapsible">
        <p>{`MedGrocer is an FDA-licensed online pharmacy.`}</p>
      </Collapsible>
      <Collapsible title="Are your medicines FDA-certified?" mdxType="Collapsible">
        <p>{`Yes. The Certificate of Product Registration can be found at the website of the Food and Drug Administration.`}</p>
      </Collapsible>
      <ContactUs mdxType="ContactUs" />
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      