import React, { useContext } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import MedicinesCard from "../Cart/MedicinesCard"
import DocumentsCard from "../Cart/DocumentsCard"
import OrderSubtotalSection from "../Cart/OrderSubtotalSection"
import Button from "../Elements/Button"

import { AppContext } from "../../context/AppContext"
import { getCouponDiscountAmount } from "./services/coupon"
import { generateDeliveryFee } from "./services/computations"
import styles from "./utils/epharmacy.module.scss"

const Cart = ({ open, location, callback, isPrescriptionDateFilled }) => {
  const { state, dispatch } = useContext(AppContext)
  const { epharmacy, documents } = state
  const { medicines, coupon } = epharmacy
  const hasNoMeds = medicines.length === 0
  const hasNoDocuments = documents.length === 0
  const buttonText = hasNoMeds
    ? "Your medicine list cannot be empty."
    : hasNoDocuments
    ? "Your prescription list cannot be empty."
    : !isPrescriptionDateFilled
    ? "Your prescription date cannot be empty."
    : "Next: Checkout"

  const handleRedirectToDeliveryDetails = () => {
    if (callback) callback()
    dispatch(
      {
        type: "HIDE_MODAL",
      },
      navigate("/epharmacy/checkout")
    )
  }

  return (
    <div className="mb-3">
      <h3 className="has-text-centered has-text-primary mt-0 is-hidden-mobile">
        My Cart
      </h3>
      <DocumentsCard
        title={`Prescriptions Uploaded (${documents.length})`}
        open={open?.documentsCard}
        location={location}
      />
      <MedicinesCard open={open?.medicinesCard} />
      {medicines.length > 0 && (
        <OrderSubtotalSection
          medicines={medicines}
          couponDiscount={getCouponDiscountAmount(
            coupon,
            medicines,
            generateDeliveryFee()
          )}
        />
      )}
      <div className={styles["cart__checkoutButtonContainer"]}>
        <Button
          className={classNames(
            `button is-size-6 has-text-weight-bold is-secondary is-fullwidth mt-1`,
            {
              "has-background-grey":
                hasNoMeds || hasNoDocuments || !isPrescriptionDateFilled,
            },
            styles["cart__checkoutButton"]
          )}
          disabled={hasNoMeds || hasNoDocuments || !isPrescriptionDateFilled}
          onClick={handleRedirectToDeliveryDetails}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default Cart
