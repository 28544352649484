import React, { useReducer } from "react"
import { AppReducer } from "./AppReducer"
import Modal from "../components/Elements/Modal"
import Toast from "../components/Layout/Toast"
import Disclaimer from "../components/Layout/Disclaimer"

const yearNow = new Date(Date.now()).getFullYear()

export const initialState = {
  documents: [],
  isOrderingAsGuest: false,
  auth: {
    email: "",
    password: "",
    confirmPassword: "",
    hasBeenVerified: false,
    firstName: "",
    lastName: "",
    mobileNumber: "",
    birthday: {
      month: { value: null },
      date: { valueL: null },
      year: "",
    },
  },
  scpwdId: {
    front: {},
    back: {},
  },
  proofOfPayment: "",
  epharmacy: {
    dispensingChannels: [],
    hasMedGrocerOption: false,
    gcAvailed: false,
    prepaidInstallmentAvailed: false,
    agreeToConsent: [],
    agreeToTermsAndConditions: [],
    medicines: [],
    patients: [],
    coupon: null,
    dispensingChannel: "",
    overrideAddress: [],
    saveOverrideAddress: [],
    deliveryAddress: {
      addressType: "",
      streetAddress: "",
      province: {
        label: "",
        value: "",
      },
      city: {
        value: null,
      },
      barangay: {
        value: null,
      },
      zipCode: {
        value: null,
      },
      notes: "",
    },
    contactPerson: ["Contact this number for order and delivery updates"],
    agreeContactPerson: [
      "If I or my authorized person to receive is not around, I authorize anyone in my delivery address to receive the medicines.",
    ],
    preferredCommunicationMethod: "",
    customerSatisfactionScore: {
      value: null,
    },
    suggestedImprovements: "",
    latestOrderNumber: "",
    firstName: "",
    lastName: "",
    doctorAccountName: "",
    employeeNumber: "",
    mobileNumber: "",
    emailAddress: "",
    scpwdIDNumber: "",
    civilStatus: "",
    beneficiaryName: "",
    beneficiaryRelationship: "",
    prcLicenseNumber: "",
    specialization: {
      value: null,
    },
    otherSpecialization: "",
    birthday: {
      month: "",
      date: "",
      year: "",
    },
    paymentOption: { label: null, value: null },
    bankName: "",
    changeFor: "",
    creditCardBank: "",
    creditCardType: "",
    ifMedicineNotAvailable: "",
    orderNotes: "",
    courierOption: "",
    preferredCourierOption: "",
    isSameDay: "",
    hospitalName: {
      value: null,
    },
    willingnessToSwitchDoctors: {
      value: null,
    },
    willingnessToTeleconsult: {
      value: null,
    },
    otherHospital: "",
    doctorName: "",
    barriersToAdherence: [],
    preferredDeliveryDate: {
      month: {
        value: null,
      },
      date: {
        value: null,
      },
      year: {
        value: yearNow,
        label: yearNow,
      },
    },
    prescriptionDate: {
      month: {
        value: null,
        label: null,
      },
      date: {
        value: null,
        label: null,
      },
      year: null,
    },
    landlineNumber: "",
    preferredDeliveryTime: [],
    authorizedPerson: {
      fullName: "",
      contactNumber: "",
    },
    patientCode: "",
    bestTimeToCall: "",
    howOftenCanWeCall: "",
    rating: null,
    feedback: "",
    healthSurvey: {
      hospitalClinic: "",
      prescribingDoctor: "",
      city: "",
      province: "",
      isMedicineSolid: false,
      medicinePrescribed: {
        value: null,
      },
      whyMedicineWasPrescribed: {
        value: null,
      },
      otherWhyMedicineWasPrescribed: "",
      diseaseType: "",
      lineOfTreatment: "",
      therapyType: "",
      combinationTherapyMedicines: "",
      prescriptionType: "",
      isFirstTimePrescribed: "",
      previousTreatment: "",
      reasonForSwitchingTreatments: [],
      previousBrands: "",
      reasonForSwitchingBrands: [],
      adverseEffectsExperiencedFromTreatment: "",
      adverseEffectsExperiencedFromBrand: "",
      hasTakenThisDrug: "",
      howManyDaysTaken: "",
      howManyTimesPerDayTaken: "",
      howManyPillsTaken: "",
      howManyTimesMissedTakingPills: "",
      dateOfLastAdministration: {
        month: {
          value: "",
        },
        date: {
          value: "",
        },
        year: "",
      },
      dateOfNextAdministration: {
        month: {
          value: "",
        },
        date: {
          value: "",
        },
        year: yearNow,
      },
      wasTherapyRescheduled: "",
      treatmentCycleNumber: "",
      whyTherapyWasRescheduled: "",
      howWellMedicineWorks: "",
      adherenceLikert: [],
      teleconsultSurvey: {
        consultingDoctorOnline: [],
        numberOfConsultations: "",
        howOftenYouConsult: "",
        openForConsulting: [],
        howMuchWillingToPay: "",
        improveExperience: [],
        otherComments: "",
      },
      interestedForNutritionistConsultation: "",
      interestedForFinancingOption: [],
      medicineAdministrationLocation: "",
      medicineAdministrator: "",
      medicineAdministrationScheduler: "",
      medicineAdministrationChallenges: [],
      medicineAdministrationTurnaround: "",
      medicineAdministrationCost: "",
      medicineAdministrationAtHome: "",
      medicineAdministrationWillingnessToPay: "",
      medicineAdministrationLocationOther: "",
      medicineAdministratorOther: "",
      medicineAdministrationSchedulerOther: "",
      medicineAdministrationChallengesOther: "",
      willingnessToPay1k: "",
      willingnessToPay2k: "",
      willingnessToPay3k: "",
      willingnessToPay4k: "",
      willingnessToPayAbove4k: "",
      psychiatryPreferredPsychiatrist: "",
      psychiatryConsultationFrequency: "",
      psychiatryConsultationCost: "",
    },
  },
  modal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    content: null,
    heading: "",
    headerClass: null,
    hideCloseButton: false,
    background: {
      color: null,
      opacity: "100",
    },
  },
  toast: {
    isActive: false,
    message: null,
    color: null,
  },
  disclaimer: {
    isActive: false,
    header: null,
    message: null,
    color: null,
  },
  patientEnrollment: {
    firstName: "",
    lastName: "",
    middleInitial: "",
    mobileNumber: "",
    emailAddress: "",
    birthday: {
      month: {
        value: null,
      },
      date: {
        value: null,
      },
      year: "",
    },
    patientAddress: {
      addressType: "",
      streetAddress: "",
      province: {
        value: null,
      },
      city: {
        value: null,
      },
      barangay: {
        value: null,
      },
    },
    secondaryContactFullName: "",
    secondaryContactMobileNumber: "",
    hospitalName: {
      value: null,
    },
    otherHospital: "",
    doctorName: "",
    prescribedMedicine: {
      value: null,
    },
    whyMedicineWasPrescribed: {
      value: null,
    },
    otherWhyMedicineWasPrescribed: "",
    diseaseType: "",
    lineOfTreatment: "",
    therapyType: "",
    combinationTherapyMedicines: "",
    peopleInHousehold: "",
    totalIncomeInHousehold: "",
    paymentMethod: [],
    companyEmployeeBenefits: "",
    privateHealthInsurance: "",
    governmentAssistance: [],
    hasPrivateInsurance: "",
    agreeToConsent: [],
    agreeToTermsAndConditions: [],
    contactThrough: "",
    patientCode: "",
    whereDidYouHearAboutUs: [],
    satisfactionScore: {
      value: null,
    },
    whatImprovementsWouldYouSuggestPatientEnrollment: "",
  },
  doctorEnrollment: {
    firstName: "",
    lastName: "",
    middleInitial: "",
    mobileNumber: "",
    emailAddress: "",
    prcLicenseNumber: "",
    specialization: {
      value: null,
    },
    otherSpecialization: "",
    primaryClinic: {
      name: {
        value: null,
      },
      otherHospital: "",
      clinicHours: "",
      clinicContactNumber: "",
      addressType: "",
      streetAddress: "",
      province: {
        value: null,
      },
      city: "",
    },
    secondaryClinic: {
      name: {
        value: null,
      },
      otherHospital: "",
      clinicHours: "",
      clinicContactNumber: "",
      addressType: "",
      streetAddress: "",
      province: {
        value: null,
      },
      city: "",
    },
    teleconsultHours: "",
    teleconsultFee: "",
    referral: {
      fullName: "",
    },
    doNotNotify: [],
    receivePatientEducationalMaterials: [],
    agreeToConsent: [],
    agreeToTermsAndConditions: [],
    contactThrough: "",
    callFrequency: "",
    bestTimeToCall: [],
    isLicensedPhysician: [],
    medicationInAccordance: [],
  },
}

const AppContext = React.createContext(initialState)

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, { ...initialState })

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Modal
        isModalActive={state.modal.isActive}
        isFullheight={state.modal.isFullheight}
        closeModal={() => dispatch({ type: "HIDE_MODAL" })}
        heading={state.modal.heading}
        modalClass={state.modal.class} // New props
        modalHeaderClass={state.modal.headerClass}
        modalBackground={state.modal.background}
        hideCloseButton={state.modal.hideCloseButton}
        isCard={state.modal.isCard}
        closeOnClickOutside={state.modal.closeOnClickOutside} // New props
      >
        {state.modal.content}
      </Modal>
      <Toast
        isActive={state.toast.isActive}
        message={state.toast.message}
        color={state.toast.color}
      />
      {children}
      <Disclaimer
        isActive={state.disclaimer.isActive}
        message={state.disclaimer.message}
        header={state.disclaimer.header}
        color={state.disclaimer.color}
        closeDisclaimer={() => dispatch({ type: "HIDE_DISCLAIMER" })}
      />
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
