const printDateValue = (date) => {
  if (!date.month.value || !date.date.value || !date.year) return "N/A"
  return `${date.month.value} ${date.date.value} ${date.year}`
}

export const patientEnrollmentTicketBody = (request) => {
  let {
    firstName,
    lastName,
    birthday,
    mobileNumber,
    emailAddress,
    patientAddress,
    hospitalName,
    otherHospital,
    doctorName,
    prescribedMedicine,
    secondaryContactFullName,
    secondaryContactMobileNumber,
    diseaseType,
    lineOfTreatment,
    therapyType,
    combinationTherapyMedicines,
    patientCode,
    satisfactionScore,
    whatImprovementsWouldYouSuggestPatientEnrollment,
    submittingUserType,
  } = request

  return `Personal Details
  First Name: ${firstName}
  Last Name: ${lastName}
  Birthdate: ${printDateValue(birthday)}
  CarePlus Patient Code: ${patientCode || "N/A"}
  Email: ${emailAddress}
  Mobile Number: ${mobileNumber}
  Secondary Contact Person: ${secondaryContactFullName || "N/A"}
  Secondary Contact Number: ${secondaryContactMobileNumber || "N/A"}
  Enrolling user: ${submittingUserType || "N/A"}
  ---
  ${patientAddress?.addressType} Address
  Street Address: ${patientAddress?.streetAddress}
  City: ${patientAddress?.city.value}
  Province: ${patientAddress?.province.value}
  Barangay: ${patientAddress?.barangay.value}
  ---
  Prescription Information
  Hospital: ${
    hospitalName?.value !== "Other" ? hospitalName?.value : otherHospital
  }
  Doctor: ${doctorName}
  Prescribed Medicine: ${prescribedMedicine?.label}
  ${diseaseType ? `\nSubtype: ${diseaseType}` : ""}${
    lineOfTreatment ? `\nLine of Treatment: ${lineOfTreatment}` : ""
  }${therapyType ? `\nTherapy Type: ${therapyType}` : ""}${
    combinationTherapyMedicines
      ? `\nCombination Therapy Medicine: ${combinationTherapyMedicines}`
      : ""
  }
  ---
  Financial Information
  How do you pay for your medicines? ${request?.paymentMethod.join(", ")}${
    request?.paymentMethod?.includes("Company employee benefits")
      ? `\nCompany employee benefits: ${request?.companyEmployeeBenefits}`
      : ""
  }${
    request?.paymentMethod?.includes("Private health insurance")
      ? `\nPrivate health insurance: ${request?.privateHealthInsurance}`
      : ""
  }${
    request?.paymentMethod?.includes("Government assistance")
      ? `\nGovernment assistance: ${request?.governmentAssistance?.join(", ")}`
      : ""
  }${
    request?.governmentAssistance?.includes("Other")
      ? `\nOther government agency: ${request?.otherGovernmentAgency}`
      : ""
  }
  ---
  Where did you hear about us?
  Where did you hear about the CarePlus program? ${request?.whereDidYouHearAboutUs.join(
    ", "
  )}${
    request?.whereDidYouHearAboutUs?.includes("Other")
      ? `\nOther: ${request?.otherReferral}`
      : ""
  }
  ---
  Feedback
  On a scale of 1 to 10, how satisfied are you with the enrollment process? ${
    satisfactionScore.value
  }
  What improvements would you suggest for the CarePlus enrollment process? ${whatImprovementsWouldYouSuggestPatientEnrollment}
  `
}
